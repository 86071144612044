<template>
    <div class="obituary-wrapper">
        <p class="h2 mb-3 font-weight-normal text-dark-tan libre-caslon text-center text-md-left">
            Recent Obituaries
        </p>

        <div class="obituaries">
            <div class="obituary" v-for="obit in obitData" :key="obit.id">
                <div class="img"><img :alt="obit.profile_image_path_alt_text" :src="obit.profile_image_path"/></div>
                <div class="content">
                    <p class="title">{{ obit.name }}</p>
                    <p class="date">{{ formatDate(obit.date_of_death) }}</p>
                </div>
                <a class="absolute-fill text-throw" :href="`/obituaries/${obit.id}${obit.permalink ? '/' + obit.permalink : ''}`" :title="`Internal link to ${obit.name}'s Obituary`">{{ obit.name }}</a>
            </div>
        </div>

        <a class="btn btn-primary mx-auto mx-md-0 w-50 w-md-auto mt-2 d-block" href="/obituaries" title="Internal link in the same tab to our obituaries page">View all</a>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    data() {
        return {
            obitData: []
        }
    },
    mounted() {
        axios.get('/obituaries/recent')
            .then(res => {
                this.obitData = res.data.data;
            })
            .catch(err => {
                console.error(err);
            })
    },
    methods: {
        formatDate(date) {
            return dayjs(date).format("MMMM D, YYYY");
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../sass/_vue-import.scss';

    .obituary-wrapper {
        padding: 0 1rem;

        @include not-desktop {
            .obituaries {
                display: flex;
                flex-wrap: wrap;
                margin: -.75rem;
                justify-content: center;

                .obituary {
                    padding: .75rem;
                    flex: 0 1 250px;
                }
            }
        }
    }

    .obituaries {
        .obituary {
            display: flex;
            align-items: stretch;
            padding: .75rem 0;
            position: relative;

            .img {
                height: 50px;
                width: 50px;
                margin-right: 1rem;
                position: relative;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .content {
                flex: 1 1 auto;

                .title {
                    @include fluid-size(18px, 22px, font-size);
                    font-family: 'Roboto Condensed', sans-serif;
                    font-weight: bold;
                    color: var(--dark-tan);
                    line-height: 1.2;
                    margin-bottom: .5rem;
                    text-decoration: underline;
                }

                .date {
                    font-size: 14px;
                    color: var(--gray);
                    line-height: 1.2;
                    margin: 0;
                }
            }
        }
    }
</style>